import React, { useState } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'
import toast, { Toaster } from 'react-hot-toast'
import Link from 'next/link'
import { redirectToHomeIfAuthenticated } from '../../utils/auth'
import Cookies from 'js-cookie'
import toastr from 'toastr'

// Trang đăng nhập
export async function getServerSideProps(context) {
  return redirectToHomeIfAuthenticated(context)
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter() // Initialize useRouter
  const [rememberMe, setRememberMe] = useState(false) // State cho checkbox

  const handleLogin = async () => {
    try {
      // const response = await axios.post(
      //   // eslint-disable-next-line no-undef
      //   `${process.env.NEXT_PUBLIC_SERVER_URL}/auth/login-user`, //gốc /login
      //   { email, password },

      //   {
      //     headers: {
      //       Authorization: `Bearer ${process.env.JWT_SECRET}`, // Định dạng: Bearer <token>
      //     },
      //     withCredentials: true,
      //   } // Đảm bảo gửi cookie với yêu cầu
      // )

      const response = await axios.post('/api/login', {
        email,
        password,
      })

      sessionStorage.setItem('loggedIn', true)

      localStorage.setItem('userId', response.data.user.id)

      if (response.status === 200) {
        // Nếu người dùng chọn "Ghi nhớ đăng nhập", lưu vào localStorage, ngược lại lưu vào sessionStorage
        if (rememberMe) {
          Cookies.set('accessToken', response.data.accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })

          Cookies.set('refreshToken', response.data.refreshToken, {
            // eslint-disable-next-line no-undef
            expires: 7, //7days
            // httpOnly: true,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })
        } else {
          // Lưu token vào cookies
          Cookies.set('accessToken', response.data.accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })
        }

        // localStorage.setItem('ewtxsdzcs', response.data.user.email)
        // document.cookie = 'isAuthenticated=true; path=/' // Lưu vào cookie

        // toast.success(
        //   'Đăng nhập thành công,hãy kiểm tra mã OTP gửi đến Telegram.'
        // )
        // // Đăng nhập thành công, gửi OTP qua Telegram
        // await axios.post('/api/send-otp', {
        //   email,
        //   userid: response.data.user.id,
        // })
        // // Redirect or navigate to another page after login
        // router.push('/verifyotp')

        toast.success('Đăng nhập thành công.')
        toastr.success(
          'Đăng nhập thành công!',
          `Xin chào ${response.data.user.username}!`
        )

        router.push('/admindashboard/homeindex')
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const message = error.response.data.message
        toast.error(message) // Hiển thị thông báo lỗi từ server
      } else {
        toast.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
      }
    }
  }

  // Hàm xử lý khi nhấn phím Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <>
      <Toaster />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className=" d-flex align-items-center auth px-0 mt-5">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src="/wolfteam.png" alt="logo" />
                  </div>
                  <h4>Xin chào! hãy bắt đầu</h4>
                  <h6 className="font-weight-light">Tiếp tục đăng nhập.</h6>

                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Địa chỉ email"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Mật khẩu"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    {/* Checkbox Ghi nhớ đăng nhập */}
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label htmlFor="rememberMe" className="ml-2">
                        Ghi nhớ đăng nhập
                      </label>
                    </div>
                    <div className="mt-3">
                      <a
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        href="#"
                        onClick={handleLogin}
                      >
                        Đăng nhập
                      </a>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <Link
                        href="/forgotpassword"
                        className="auth-link text-primary"
                      >
                        Quên mật khẩu?
                      </Link>
                    </div>
                    {/* <div className="text-center mt-4 font-weight-light">
                      Chưa có tài khoản?{' '}
                      <Link href="/register" className="text-primary">
                        Tạo mới
                      </Link>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
